<template>
        <div class="row">
                  <div class="col-lg-6">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height shadow-none blog-grid">
                        <div class="iq-card-body p-0">
                           <div class="row align-items-center">
                              <div class="col-lg-6">
                                 <div class="image-block mt-3">
                                    <img src="@/assets/images/blog/b1.jpg" class="img-fluid rounded w-100" alt="blog-img">
                                 </div>
                              </div>
                              <div class="col-lg-6">
                                 <div class="blog-description shadow-none p-3 text-center">
                                    <div class="date mb-1"><a href="#" tabindex="-1">4 Month ago</a></div>
                                    <h5 class="mb-2">Containing coronavirus spread comes</h5>
                                    <div class="group-smile mt-4 d-flex flex-wrap align-items-center justify-content-between">
                                       <div class="iq-media-group">
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/01.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/02.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/03.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/07.png" alt="">
                                          </a>
                                       </div>
                                       <div class="comment"><i class="ri-chat-3-line mr-2"></i>7 comments</div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="iq-card-transparent card-stretch card-height blog-grid blog-single">
                        <div class="card-body p-0 position-relative">
                           <div class="image-block">
                              <img src="@/assets/images/blog/03.jpg" class="img-fluid rounded w-100" alt="blog-img">
                           </div>
                           <div class="blog-description p-3">
                              <div class="date"><a href="#" tabindex="-1">3 Month ago</a></div>
                              <h5 class="mb-2">Containing coronavirus spread comes</h5>
                              <div class="d-flex align-items-center justify-content-between">
                                 <div class="like"><i class="ri-thumb-up-line pr-2"></i>20 like</div>
                                 <div class="comments"><i class="las la-comment-alt pr-2"></i>351 Comments</div>
                                 <div class="share"><i class="ri-share-forward-line pr-2"></i>share</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="iq-card-transparent card-stretch card-height blog-grid blog-single">
                        <div class="card-body p-0 position-relative">
                           <div class="image-block">
                              <img src="@/assets/images/blog/04.jpg" class="img-fluid rounded w-100" alt="blog-img">
                           </div>
                           <div class="blog-description p-3">
                              <div class="date"><a href="#" tabindex="-1">3 Month ago</a></div>
                              <h5 class="mb-2">Containing coronavirus spread comes</h5>
                              <div class="d-flex align-items-center justify-content-between">
                                 <div class="like"><i class="ri-thumb-up-line pr-2"></i>20 like</div>
                                 <div class="comments"><i class="las la-comment-alt pr-2"></i>351 Comments</div>
                                 <div class="share"><i class="ri-share-forward-line pr-2"></i>share</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height shadow-none blog-grid">
                        <div class="iq-card-body p-0">
                           <div class="row align-items-center">
                              <div class="col-lg-6"><div class="blog-description shadow-none p-3 text-center">
                                    <div class="date mb-1"><a href="#" tabindex="-1">4 Month ago</a></div>
                                    <h5 class="mb-2">Containing coronavirus spread comes</h5>
                                    <div class="group-smile mt-4 d-flex flex-wrap align-items-center justify-content-between">
                                       <div class="iq-media-group">
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/01.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/02.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/03.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/07.png" alt="">
                                          </a>
                                       </div>
                                       <div class="comment"><i class="ri-chat-3-line mr-2"></i>7 comments</div>
                                    </div>
                                   </div>
                              </div>
                              <div class="col-lg-6">
                                 <div class="image-block mt-3">
                                    <img src="@/assets/images/blog/b2.jpg" class="img-fluid rounded w-100" alt="blog-img">
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height shadow-none blog-grid">
                        <div class="iq-card-body p-0">
                           <div class="row align-items-center">
                              <div class="col-lg-6">
                                 <div class="image-block mt-3">
                                    <img src="@/assets/images/blog/b3.jpg" class="img-fluid rounded w-100" alt="blog-img">
                                 </div>
                              </div>
                              <div class="col-lg-6">
                                 <div class="blog-description shadow-none p-3 text-center">
                                    <div class="date mb-1"><a href="#" tabindex="-1">4 Month ago</a></div>
                                    <h5 class="mb-2">Containing coronavirus spread comes</h5>
                                    <div class="group-smile mt-4 d-flex flex-wrap align-items-center justify-content-between">
                                       <div class="iq-media-group">
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/01.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/02.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/03.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/07.png" alt="">
                                          </a>
                                       </div>
                                       <div class="comment"><i class="ri-chat-3-line mr-2"></i>7 comments</div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="iq-card-transparent  card-stretch card-height blog-grid blog-single">
                        <div class="card-body p-0 position-relative">
                           <div class="image-block">
                              <img src="@/assets/images/blog/01.jpg" class="img-fluid rounded w-100" alt="blog-img">
                           </div>
                           <div class="blog-description p-3">
                              <div class="date"><a href="#" tabindex="-1">3 Month ago</a></div>
                              <h5 class="mb-2">Containing coronavirus spread comes</h5>
                              <div class="d-flex align-items-center justify-content-between">
                                 <div class="like"><i class="ri-thumb-up-line pr-2"></i>20 like</div>
                                 <div class="comments"><i class="las la-comment-alt pr-2"></i>351 Comments</div>
                                 <div class="share"><i class="ri-share-forward-line pr-2"></i>share</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="iq-card-transparent  card-stretch card-height blog-grid blog-single">
                        <div class="card-body p-0 position-relative">
                           <div class="image-block">
                              <img src="@/assets/images/blog/03.jpg" class="img-fluid rounded w-100" alt="blog-img">
                           </div>
                           <div class="blog-description p-3">
                              <div class="date"><a href="#" tabindex="-1">3 Month ago</a></div>
                              <h5 class="mb-2">Containing coronavirus spread comes</h5>
                              <div class="d-flex align-items-center justify-content-between">
                                 <div class="like"><i class="ri-thumb-up-line pr-2"></i>20 like</div>
                                 <div class="comments"><i class="las la-comment-alt pr-2"></i>351 Comments</div>
                                 <div class="share"><i class="ri-share-forward-line pr-2"></i>share</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height shadow-none blog-grid">
                        <div class="iq-card-body p-0">
                           <div class="row align-items-center">
                              <div class="col-lg-6">
                                 <div class="blog-description shadow-none p-3 text-center">
                                    <div class="date mb-1"><a href="#" tabindex="-1">4 Month ago</a></div>
                                    <h5 class="mb-2">Containing coronavirus spread comes</h5>
                                    <div class="group-smile mt-4 d-flex flex-wrap align-items-center justify-content-between">
                                       <div class="iq-media-group">
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/01.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/02.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/03.png" alt="">
                                          </a>
                                          <a href="#" class="iq-media">
                                          <img class="img-fluid rounded-circle" src="@/assets/images/icon/07.png" alt="">
                                          </a>
                                       </div>
                                       <div class="comment"><i class="ri-chat-3-line mr-2"></i>7 comments</div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-lg-6">
                                 <div class="image-block mt-3">
                                    <img src="@/assets/images/blog/b4.jpg" class="img-fluid rounded w-100" alt="blog-img">
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
</template>

<script>
export default {
  name: 'BlogGrid'
}
</script>
